// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SignInForm from './SignInForm';
const packageJson = require('../package.json');

const Navbar = () => {
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    age: '',
    password: ''
  });
  const token = localStorage.getItem('token');
  const isSignedIn = token ? true : false;
  console.log('isSignedIn:', isSignedIn);
  const navigate = useNavigate();

  const handleSignInOpen = () => {
    setSignUpOpen(true);
  };

  const handleSignInClose = () => {
    setSignUpOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCreateUser = async () => {
    try {
      // API call to create the user
      const response = await fetch('https://scope-api.trl-ai.com/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const user = await response.json();
      console.log('User created:', user);
      setOpen(false); // Close the modal on success
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography align='left' variant="h6" style={{ flexGrow: 1 }}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Scope Remote {packageJson.version}
              </Link>
          </Typography>
          {/* { isSignedIn ? (
            <>
              <Button color="inherit" onClick={handleSignInOpen}>Sign In</Button>
              <Button color="inherit" onClick={handleClickOpen}>Sign Up</Button>
            </>
          ) : (
            <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
          )} */}
          <Button color="inherit" onClick={handleSignInOpen}>Sign In</Button>
          <Button color="inherit" onClick={handleClickOpen}>Sign Up</Button>

        </Toolbar>
      </AppBar>

      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Sign Up</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="age"
          label="Age"
          type="age"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
        />
        {/* Add other fields as necessary */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreateUser}>Create</Button>
      </DialogActions>
      </Dialog>
      <SignInForm open={signUpOpen} handleClose={handleSignInClose} />
    </>
  );
};

export default Navbar;
