import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const SignInForm = ({ open, handleClose }) => {

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    const navigate = useNavigate(); // Hook for navigation

    const handleInputChange = (e) => {
        const { name, value } = e.target;
            setCredentials(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSignIn = async () => {
        try {
            // Sign in logic here (API call to authenticate the user)
            const response = await fetch('https://scope-api.trl-ai.com/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Store the token
            localStorage.setItem('token', data.token);
            localStorage.setItem('role', data.role);

            // Navigate based on the user's role
            if (data.role === 'patient') {
                navigate('/patient-profile');
                const token = localStorage.getItem('token');
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.userId;
                const clear_response = await fetch(`https://scope-api.trl-ai.com/users/${userId}/clear_room`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({}),
                });
                if (!clear_response.ok) {
                    throw new Error(`HTTP error! Status: ${clear_response.status}`);
                }
            } else if (data.role === 'doctor') {
                navigate('/doctor-profile');
            }

            handleClose(); // Close the modal on success
        } catch (error) {
            console.error('Error:', error);
            // Handle errors (e.g., show an error message)
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Sign In</DialogTitle>
            <DialogContent>
                <TextField
                    autofocus
                    margin='dense'
                    name='email'
                    label='Email Address'
                    type='email'
                    fullWidth
                    variant='standard'
                    onChange={handleInputChange}
                />
                <TextField
                    margin='dense'
                    name='password'
                    label='Password'
                    type='password'
                    fullWidth
                    variant='standard'
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSignIn}>Log In</Button>
            </DialogActions>
        </Dialog>
    );
        
};

export default SignInForm;