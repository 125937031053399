import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, TextField, Button, AppBar, Toolbar, CircularProgress } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import AppointmentForm from './AppointmentForm';

const temp_appointments = [
    {
        "date": "02/10/24",
        "patient": "Test",
        "roomid": 234578
    },
    {
        "date": "02/11/24",
        "patient": "Tayler Mauldin",
        "roomid": "test456"
    }
]

const appointmentItemStyles = {
    border: 1,
    borderRadius: '8px',
    boxShadow: '2px 2px 4px #aaa',
    p: 2,
    mb: 2,
    bgcolor: 'background.paper',
};

// Modify ensureJanusRoom to handle WebRTC
async function ensureJanusRoom(roomId) {
    return new Promise((resolve, reject) => {
        const socket = new WebSocket('wss://janus.trl-ai.com/janus', 'janus-protocol');
        let sessionId, handleId;

        socket.onopen = () => {
            console.log('WebSocket connection established.');

            // Create a new session
            const createSessionMessage = {
                janus: 'create',
                transaction: `create-session-${Date.now()}`
            };
            socket.send(JSON.stringify(createSessionMessage));
        };

        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            console.log('Received message:', data);

            // Handle session creation
            if (data.janus === 'success' && data.transaction.startsWith('create-session')) {
                sessionId = data.data.id;
                console.log(`Session created with ID: ${sessionId}`);

                // Attach to VideoRoom plugin
                const attachPluginMessage = {
                    janus: 'attach',
                    plugin: 'janus.plugin.videoroom',
                    transaction: `attach-plugin-${Date.now()}`,
                    session_id: sessionId
                };
                socket.send(JSON.stringify(attachPluginMessage));
            }

            // Handle plugin attachment
            else if (data.janus === 'success' && data.transaction.startsWith('attach-plugin')) {
                handleId = data.data.id;
                console.log(`Attached to plugin with handle ID: ${handleId}`);

                // Check if room exists
                const checkRoomMessage = {
                    janus: 'message',
                    body: { request: 'exists', room: roomId },
                    transaction: `check-room-${Date.now()}`,
                    session_id: sessionId,
                    handle_id: handleId
                };
                socket.send(JSON.stringify(checkRoomMessage));
            }

            // Handle room existence response
            else if (data.janus === 'success' && data.transaction.startsWith('check-room')) {
                const exists = data.plugindata.data.exists;
                if (exists) {
                    console.log(`Room ${roomId} exists.`);
                    resolve(roomId);  // Room exists, resolve promise
                } else {
                    console.log(`Room ${roomId} does not exist. Creating room.`);

                    // Create the room
                    const createRoomMessage = {
                        janus: 'message',
                        body: { request: 'create', room: roomId, publishers: 6 },
                        transaction: `create-room-${Date.now()}`,
                        session_id: sessionId,
                        handle_id: handleId
                    };
                    socket.send(JSON.stringify(createRoomMessage));
                }
            }

            // Handle room creation response
            else if (data.janus === 'success' && data.transaction.startsWith('create-room')) {
                const newRoomId = data.plugindata.data.room;
                console.log(`Room ${newRoomId} created successfully.`);
                resolve(newRoomId);  // Room created, resolve promise
            }

            // Handle errors
            else if (data.janus === 'error') {
                console.error('Error from Janus:', data.error);
                reject(data.error);  // Reject promise on error
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);  // Reject promise on WebSocket error
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed.');
        };
    });
}

const DoctorProfile = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [requestApptOpen, setRequestApptOpen] = useState(false);
    const [appointments, setAppointments] = useState(temp_appointments);
    const navigate = useNavigate();

    const handleRequestApptOpen = () => {
        setRequestApptOpen(true);
    };

    const handleRequestApptClose = () => {
        setRequestApptOpen(false);
    };

    const handleAddAppointment = (newAppointment) => {
        console.log("handleAddAppointment...")
        const appointmentToAdd = {
            date: newAppointment.date, // Ensure format matches, e.g., "MM/DD/YY"
            doctor: newAppointment.doctor,
            roomid: newAppointment.roomid || "defaultRoomId" // Provide a default or derive from newAppointment
        };
        setAppointments((currentAppointments) => [...currentAppointments, appointmentToAdd]);
    };

    // TODO: Handle joining a room
    const handleJoinAppointment = async (appointment) => {
        console.log(`Joining appointment: ${JSON.stringify(appointment)}`);
        const roomId = appointment.roomid;
        console.log(`Checking or creating room: ${roomId}`);
    
        try {
            const janusRoomId = await ensureJanusRoom(roomId);  // Ensure room exists/created
            console.log(`Room confirmed: ${janusRoomId}`);
            
            navigate(`/video-session/${janusRoomId}`);  // Navigate to the video session
        } catch (error) {
            console.error('Error during room handling:', error);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                // TODO: Create some good visual for this
                console.error('No token found');
                setLoading(false);
                return;
            }

            try {
                // Decode the JWT token to get the user's ID
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.userId;

                const response = await fetch(`https://scope-api.trl-ai.com/users/${userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error:', error);
                // Handle errors appropriately
            } finally {
                setLoading(false);
            }

        };

        fetchUserData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (!userData) {
        return <Typography>No user data found</Typography>;
    }

    return (
        <Grid container spacing={4} padding={2}>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
                <Typography>Doctor Name: {userData.name}</Typography>
            </Grid>
            <Grid item md={3}>
                {appointments && appointments.map((appointment, index) => (
                    <Grid item key={index} md={12} sx={appointmentItemStyles}>
                        <Typography>Appointment Date: {appointment.date}</Typography>
                        <Typography>Patient: {appointment.patient}</Typography>
                        <Button onClick={() => handleJoinAppointment(appointment)}>Join</Button>
                    </Grid>
                ))}
                <Button color="inherit" onClick={handleRequestApptOpen}>Request Appointment</Button>
            </Grid>
            <Grid item md={3}></Grid>

            {/* AppointmentForm modal */}
            <AppointmentForm open={requestApptOpen} handleClose={handleRequestApptClose} onAddAppointment={handleAddAppointment} />
        </Grid>
    );
}    

export default DoctorProfile;