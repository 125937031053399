import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import RecordRTC from 'recordrtc';
import { useNavigate } from 'react-router-dom';
const packageJson = require('../package.json');
console.log('App version: ' + packageJson.version);


const StyledVideoContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%', // Full height within the parent container
  overflow: 'hidden',
  border: '2px solid #ccc',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#000',
});

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const HoverVideoContainer = styled('div')({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
});

const SmallVideo = styled('video')({
  width: '150px',
  height: 'auto',
  border: '2px solid #ccc',
});

const Toolbar = styled('div')({
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '10px',
  padding: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '8px',
});

const VideoSession = () => {
  const [room, setRoom] = useState('');
  const [recording, setRecording] = useState(false);
  const [mute, setMute] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState('');
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const deviceVideoRef = useRef(null);
  const canvasRef = useRef(null);
  const socketRef = useRef(null);
  const pcsRef = useRef({});
  const localStreamRef = useRef(null);
  const roomRef = useRef(null);
  const recorderRef = useRef(null);
  const role = localStorage.getItem('role');
  const deviceID = '123'; // TODO: Need to fix this!!
  const { roomId } = useParams();
  const navigate = useNavigate(); // Hook for navigation
  const FRAME_HEIGHT = 720;
  const FRAME_WIDTH = 1280;
  const FPS = 10;

  const createPeerConnection = (socketId, room, isDevice) => {
    if (pcsRef.current[socketId]) return;
    roomRef.current = room;
    pcsRef.current[socketId] = new RTCPeerConnection({
      iceServers: [
        // { urls: 'stun:stun.relay.metered.ca:80' },
        {
          urls: 'turn:a.relay.metered.ca:80',
          username: 'f7d260b72ad1a7d7d2ad79c9',
          credential: 'mEfyvohPVIda0MV2',
        },
        {
          urls: 'turn:a.relay.metered.ca:80?transport=tcp',
          username: 'f7d260b72ad1a7d7d2ad79c9',
          credential: 'mEfyvohPVIda0MV2',
        },
        {
          urls: 'turn:a.relay.metered.ca:443',
          username: 'f7d260b72ad1a7d7d2ad79c9',
          credential: 'mEfyvohPVIda0MV2',
        },
        {
          urls: 'turn:a.relay.metered.ca:443?transport=tcp',
          username: 'f7d260b72ad1a7d7d2ad79c9',
          credential: 'mEfyvohPVIda0MV2',
        },
      ],
    });

    if (isDevice) {
      console.log('Device Peer Connection Created...');
      pcsRef.current[socketId].onicecandidate = (event) => {
        if (event.candidate) {
          socketRef.current.emit(
            'deviceCandidate',
            roomRef.current,
            event.candidate,
            socketRef.current.id
          );
        }
      };

      pcsRef.current[socketId].ontrack = (event) => {
        if (event.streams && event.streams[0]) {
          deviceVideoRef.current.srcObject = event.streams[0];
        }
      };
    } else {
      pcsRef.current[socketId].onicecandidate = (event) => {
        console.log('Sending ICE candidate...');
        if (event.candidate) {
          socketRef.current.emit(
            'candidate',
            roomRef.current,
            socketId,
            event.candidate
          );
        }
      };

      pcsRef.current[socketId].ontrack = (event) => {
        if (event.streams && event.streams[0]) {
          console.log('Received remote track...');
          remoteVideoRef.current.srcObject = event.streams[0];
        }
      };
    }

    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => {
        pcsRef.current[socketId].addTrack(track, localStreamRef.current);
      });
    }
  };

  const handleStartRecording = () => {
    const canvas = canvasRef.current;
    const stream = canvas.captureStream(25); // Capture the canvas stream at 25 FPS
    const recorder = new RecordRTC(stream, {
      type: 'video',
      mimeType: 'video/webm; codecs=h264'
    });

    recorderRef.current = recorder;

    recorder.startRecording();
    setRecording(true);
  };

  const handleStopRecording = async () => {
    const recorder = recorderRef.current;
    await recorder.stopRecording();
    const blob = await recorder.getBlob();
    const url = URL.createObjectURL(blob);
    setRecordingUrl(url);
    setRecording(false);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'recording.webm';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleMute = () => {
    const stream = localStreamRef.current;
    stream.getAudioTracks().forEach((track) => {
      track.enabled = !track.enabled;
    });
    setMute(!mute);
  };

  async function clear_room(token) {
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;
    const clear_response = await fetch(`https://scope-api.trl-ai.com/users/${userId}/clear_room`, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({}),
  });
  if (!clear_response.ok) {
      throw new Error(`HTTP error! Status: ${clear_response.status}`);
  }
  }

  const handleHangUp = async () => {
    // 1. Close all peer connections
    for (const socketId in pcsRef.current) {
      pcsRef.current[socketId].close();
      delete pcsRef.current[socketId];
    }

    // 2. Turn off the user's camera (stop media tracks)
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    socketRef.current.emit('leave', room);

    // make sure that role is still defined
    if (!role) {
      navigate('/');
    } else {
      // 4. Redirect the user to their respective page
      if (role === 'doctor') {
        navigate('/doctor-profile'); // Navigate to doctor profile
      } else if (role === 'patient') {
        navigate('/patient-profile'); // Navigate to patient profile
        clear_room(localStorage.getItem('token'));
      }
    }
  };

  useEffect(() => {

    console.log('Connecting to the Server!');
    socketRef.current = io.connect('https://signal.trl-ai.com', {
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    socketRef.current.on('created', (room, socketId) => {
      console.log(`Created room ${room}, with Socket ID: ${socketId}`);
      createPeerConnection(socketId, room);
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: FRAME_WIDTH, max: FRAME_WIDTH }, // Set to 1920x1080
            height: { ideal: FRAME_HEIGHT, max: FRAME_HEIGHT },
            frameRate: { ideal: FPS, max: 60 }, // Frame rate set to 20 FPS
          },
          audio: true,
        })
        .then((stream) => {
          localStreamRef.current = stream;
          localVideoRef.current.srcObject = stream;
          stream.getTracks().forEach((track) => {
            pcsRef.current[socketId].addTrack(track, stream);
          });
        })
        .catch((error) => {
          console.error('Error accessing media devices.', error);
        });
    });

    socketRef.current.on('joined', (room, socketId) => {
      console.log(`Joined room ${room}, with Socket ID: ${socketId}`);
      createPeerConnection(socketId, room);
      navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: FRAME_WIDTH, max: FRAME_WIDTH }, // Set to 1920x1080
            height: { ideal: FRAME_HEIGHT, max: FRAME_HEIGHT },
            frameRate: { ideal: FPS, max: 60 }, // Frame rate set to 20 FPS
        },
        audio: true,
      })
        .then((stream) => {
          localStreamRef.current = stream;
          localVideoRef.current.srcObject = stream;
          stream.getTracks().forEach((track) => {
            pcsRef.current[socketId].addTrack(track, stream);
          });
          socketRef.current.emit('ready', room, socketId);
        })
        .catch((error) => {
          console.error('Error accessing media devices.', error);
        });
    });

    const handleDeviceOffer = (socketId, description, device_id) => {
      console.log('Received Device Offer...');
      const isDevice = device_id === deviceID;
      if (!description || !description.type || !description.sdp) {
        console.error('Invalid offer description received:', description);
        return;
      }
      createPeerConnection(socketId, roomRef.current, isDevice);
    
      pcsRef.current[socketId]
        .setRemoteDescription(new RTCSessionDescription(description))
        .then(() => pcsRef.current[socketId].createAnswer())
        .then((answer) => {
          return pcsRef.current[socketId].setLocalDescription(answer); // Ensure the promise is returned
        })
        .then(() => {
          // Wait for the local description to be set
          const localDescription = pcsRef.current[socketId].localDescription;
          console.log("Sending Device Answer...");
          socketRef.current.emit(
            'deviceAnswer',
            roomRef.current,
            localDescription,
            socketRef.current.id,
            device_id
          );
        })
        .catch((error) => {
          console.error('Error handling device offer or setting local description:', error);
        });
    };

    const handleRemoteOffer = (socketId, description, room) => {
      console.log(description);
      if (!description || !description.type || !description.sdp) {
        console.error('Invalid offer description received:', description);
        return;
      }
      console.log(socketId);
      console.log(description);
      createPeerConnection(socketId, room);
    
      pcsRef.current[socketId]
        .setRemoteDescription(new RTCSessionDescription(description))
        .then(() => pcsRef.current[socketId].createAnswer())
        .then((answer) => {
          return pcsRef.current[socketId].setLocalDescription(answer); // Ensure setLocalDescription completes
        })
        .then(() => {
          // Wait for the local description to be set
          const localDescription = pcsRef.current[socketId].localDescription;
          console.log(localDescription);
          socketRef.current.emit(
            'answer',
            room,
            localDescription,
            socketId
          );
        })
        .catch((error) => {
          console.error('Error handling remote offer or setting local description:', error);
        });
    };

    socketRef.current.on('offer', (socketId, description, room, device_id) => {
      console.log('Received an offer...');
      if (device_id === deviceID) {
        console.log('Device Offer Received...');
        handleDeviceOffer(socketId, description, deviceID);
      } else {
        console.log('Remote Offer Received...');
        handleRemoteOffer(socketId, description, room);
      }
    });

    socketRef.current.on('answer', (socketId, description) => {
      console.log('Received an answer...');
      pcsRef.current[socketId].setRemoteDescription(
        new RTCSessionDescription(description)
      );
    });

    socketRef.current.on('candidate', (socketId, candidate) => {
      pcsRef.current[socketId].addIceCandidate(new RTCIceCandidate(candidate));
    });

    socketRef.current.on('ready', (room) => {
      const socketId = socketRef.current.id;
      createPeerConnection(socketId, room);
    
      pcsRef.current[socketId]
        .createOffer()
        .then((offer) => {
          console.log(offer);
          return pcsRef.current[socketId].setLocalDescription(offer);
        })
        .then(() => {
          // Wait for the local description to be set
          const localDescription = pcsRef.current[socketId].localDescription;
          console.log(localDescription);
          socketRef.current.emit('offer', room, localDescription, socketId);
        })
        .catch((error) => {
          console.error('Error creating or setting local description:', error);
        });
    });

    setRoom(roomId);
    socketRef.current.emit('create or join', roomId);

    // Map to store handleId to peerConnection
    const handlePeerConnectionMap = new Map();
    const socket = new WebSocket('wss://janus.trl-ai.com/janus', 'janus-protocol');
    let sessionId;
    const heartbeatInterval = 30 * 1000; // Send heartbeat every 30 seconds
    let heartbeat;
    let subscriptionHandleId;
    let subscriptionFeedId;
    let peerConnection;

    // Subscribe to a publisher's feed
    function subscribeToPublisher(feedId) {
      subscriptionFeedId = feedId; // Store for later use
      console.log(`Subscribing to publisher with feed ID: ${feedId}`);
  
      const attachPluginMessage = {
          janus: 'attach',
          plugin: 'janus.plugin.videoroom',
          transaction: `attach-subscription-${Date.now()}`,
          session_id: sessionId
      };
      socket.send(JSON.stringify(attachPluginMessage));
    }

    console.log(`Joining Janus room: ${roomId}`);

    socket.onopen = () => {
        console.log('WebSocket connected to Janus.');

        const createSessionMessage = {
            janus: 'create',
            transaction: `create-session-${Date.now()}`
        };
        socket.send(JSON.stringify(createSessionMessage));

        // Start the heartbeat to keep the session alive
        heartbeat = setInterval(() => {
            const heartbeatMessage = {
                janus: 'keepalive',
                session_id: sessionId,
                transaction: `keepalive-${Date.now()}`
            };
            socket.send(JSON.stringify(heartbeatMessage));
            console.log('Heartbeat sent to Janus.');
        }, heartbeatInterval);
    };

    socket.onmessage = async (event) => {
        const data = JSON.parse(event.data);
        console.log('Received message:', data);

        if (data.janus === 'ack') {
            console.log('Received Janus ACK.');
            return;
        }

        if (data.janus === 'success' && data.transaction.startsWith('create-session')) {
            sessionId = data.data.id;
            console.log(`Session created with ID: ${sessionId}`);

            const attachPluginMessage = {
                janus: 'attach',
                plugin: 'janus.plugin.videoroom',
                transaction: `attach-plugin-${Date.now()}`,
                session_id: sessionId
            };
            socket.send(JSON.stringify(attachPluginMessage));
        } else if (data.janus === 'success' && data.transaction.startsWith('attach-plugin')) {
            const handleId = data.data.id;
            console.log(`Attached to plugin with handle ID: ${handleId}`);

            // After attaching the plugin
            const joinMessage = {
              janus: 'message',
              body: {
                  request: 'join',
                  room: parseInt(roomId, 10),
                  ptype: 'publisher',
                  audio: false,
                  video: false
              },
              transaction: `join-publisher-${Date.now()}`,
              session_id: sessionId,
              handle_id: handleId
          };
          socket.send(JSON.stringify(joinMessage));

        } else if (data.janus === 'event' && data.plugindata?.data?.publishers) {
            const publishers = data.plugindata.data.publishers;
            console.log('Publishers available:', publishers);

            if (publishers.length > 0) {
                const publisher = publishers[0]; // Since there's only one
                subscribeToPublisher(publisher.id);
            }
        } else if (data.janus === 'success' && data.transaction.startsWith('attach-subscription')) {
            subscriptionHandleId = data.data.id;
            console.log(`Attached subscription handle ID: ${subscriptionHandleId}`);
          
            // Send 'join' request as subscriber with feed ID
            const joinMessage = {
              janus: 'message',
              body: {
                  request: 'join',
                  room: parseInt(roomId, 10),
                  ptype: 'subscriber',
                  feed: subscriptionFeedId // Include the feed ID here
              },
              transaction: `join-subscription-${Date.now()}`,
              session_id: sessionId,
              handle_id: subscriptionHandleId
          };
          socket.send(JSON.stringify(joinMessage));

        } else if (data.janus === 'event' && data.jsep) {
            console.log('Received SDP offer from Janus.');

            if (!peerConnection) {
                peerConnection = new RTCPeerConnection({
                    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
                });

                // Handle remote track event
                peerConnection.ontrack = (event) => {
                    console.log('Received remote track.');
                    if (event.streams && event.streams[0]) {
                        deviceVideoRef.current.srcObject = event.streams[0];
                    }
                };

                // Handle new ICE candidates
                peerConnection.onicecandidate = (event) => {
                    if (event.candidate) {
                        console.log('Sending ICE candidate to Janus.');
                        const candidateMessage = {
                            janus: 'trickle',
                            candidate: event.candidate,
                            transaction: `trickle-${Date.now()}`,
                            session_id: sessionId,
                            handle_id: subscriptionHandleId // Use the subscription handle
                        };
                        socket.send(JSON.stringify(candidateMessage));
                    } else {
                        console.log('All ICE candidates sent.');
                    }
                };
            }

            await peerConnection.setRemoteDescription(new RTCSessionDescription(data.jsep));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);

            const startMessage = {
                janus: 'message',
                body: { request: 'start' },
                jsep: peerConnection.localDescription,
                transaction: `start-${Date.now()}`,
                session_id: sessionId,
                handle_id: subscriptionHandleId // Use the subscription handle
            };
            socket.send(JSON.stringify(startMessage));

        } else if (data.janus === 'trickle') {
            // Handle remote ICE candidates from Janus
            const candidate = data.candidate;
            const senderHandleId = data.sender;
            if (peerConnection && candidate && senderHandleId === subscriptionHandleId) {
                await peerConnection.addIceCandidate(candidate);
            }
        } else if (data.janus === 'event' && data.plugindata?.data?.leaving) {
            const leavingFeed = data.plugindata.data.leaving;
            console.log(`Publisher left: ${leavingFeed}`);
            // Handle the case where the publisher leaves the room
        } else if (data.janus === 'webrtcup') {
            console.log('WebRTC connection established.');
        } else if (data.janus === 'hangup') {
            console.log('WebRTC connection closed.');
            // Clean up peer connection if necessary
        }
    };

    socket.onerror = (error) => {
        console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed.');
        clearInterval(heartbeat); // Stop heartbeat on close
    };

    return () => {
        if (socket) {
            socket.close();
            clearInterval(heartbeat); // Clean up heartbeat on unmount
        }
    };
  }, [roomId]);
  


  return (
    <Container maxWidth="xl" disableGutters>
      <Box height="calc(100vh - 64px)" display="flex" flexDirection="column">
        <Grid container spacing={4} padding={2} flex={1} overflow="hidden">
          <Grid item xs={12} style={{ height: '100%' }}>
            <StyledVideoContainer>
              <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
              <StyledVideo
                id="deviceVideo"
                ref={deviceVideoRef}
                autoPlay
                playsInline
              />
              <HoverVideoContainer>
                <SmallVideo
                  id="localVideo"
                  ref={localVideoRef}
                  autoPlay
                  playsInline
                  muted
                />
                <SmallVideo
                  id="remoteVideo"
                  ref={remoteVideoRef}
                  autoPlay
                  playsInline
                />
              </HoverVideoContainer>
              <Toolbar>
                {recording ? (
                  <Button variant="contained" color="secondary" onClick={handleStopRecording}>
                    Stop Recording
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleStartRecording}>
                    Start Recording
                  </Button>
                )}
                <Button variant="contained" color={mute ? "warning" : "primary"} onClick={handleMute}>
                  {mute ? "Unmute" : "Mute"}
                </Button>
                <Button variant="contained" color="error" onClick={handleHangUp}>
                  Hang Up
                </Button>
              </Toolbar>
            </StyledVideoContainer>
            {recordingUrl && (
              <Box mt={2}>
                <video src={recordingUrl} controls width="100%" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default VideoSession;
