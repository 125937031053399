import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoSession from './VideoSession';
import PatientProfile from './PatientProfile';
import LandingPage from './LandingPage';
import Navbar from './NavBar';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import DoctorProfile from './DoctorProfile';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
        <Route path="/" element={<LandingPage />} />
          <Route path="/video-session" element={<VideoSession />} />
          <Route path="/video-session/:roomId" element={<VideoSession />} />
          <Route path="/patient-profile" element={<PatientProfile />} />
          <Route path="/doctor-profile" element={<DoctorProfile />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
